body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4d4d4d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.modal-header .close {
  font-family: "Line Awesome Free" !important;
  font-weight: 900 !important;
}

.modal-header .close span[aria-hidden="true"]::before {
  font-family: "Line Awesome Free" !important;
  font-weight: 900 !important;
  content: "\f15c" !important;
}

body {
  overflow: hidden;
  margin: 0px;
  font-family: "Poppins", "Roboto", sans-serif !important;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
a,
input {
  font-family: "Poppins", "Roboto", sans-serif !important;
}

.spinner-border {
  width: 5rem !important;
  height: 5rem !important;
  border-width: 0.45em !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* button */
::-webkit-scrollbar-button {
  background: #222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* The track NOT covered by the handle.
::-webkit-scrollbar-track-piece {
  background: #000;
}

/* Corner */
::-webkit-scrollbar-corner {
  background: #999;
}

/* Resizer */
::-webkit-resizer {
  background: #111;
}

/* for sm */

.custom-switch.custom-switch-sm .custom-control-label {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

.MuiDialog-paper {
  padding: 18px;
}

.table td,
.table th {
  padding: 16px !important;
  vertical-align: top;
  border-top: none !important;
}

.table thead td,
.table thead th {
  border-bottom: 1px solid rgb(81, 81, 81) !important;
}

.measurementLabel {
  font-family: monospace;
  background-color: black;
  color: white;
}