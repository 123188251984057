.inspection-detail-modal .modal-content {
  background-color: #f2f2f6;
}

.inspection-detail-modal::before {
  content: "";
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.inspection-detail-modal {
  pointer-events: none;
}

.inspection-detail-modal.modal-open {
  pointer-events: auto;
}

.inspection-detail-modal .modal-dialog {
  max-width: 890px;
}

.inspection-detail-modal .modal-header {
  background-color: #323232;
  color: #F5F5F5;
  border-radius: 0px;
  border-bottom: 2px solid #525252;
}

.inspection-detail-modal .modal-content {
  border: 2px solid #525252;
  border-radius: 3px !important;
}

.inspection-detail-modal .modal-body {
  background-color: #2e2e2e;
  overflow: hidden;
}

.inspection-detail-modal-left-side {
  transition: background-color 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inspection-detail-modal-right-side {
  width: calc(100% - 66px);
}

.inspection-detail-modal-content {
  display: flex;
  overflow: hidden !important;
}

.inspection-detail-modal .list-group-item {
  background-color: #2e2e2e;
  color: #f5f5f5;
}

.inspection-detail-modal .modal-title {
  font-size: 1.075rem !important;
  font-weight: bolder;
}

.inspection-detail-modal .modal-header {
  padding: 1rem !important;
}

.modal,
.modal * {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.node-leading {
  border-radius: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin: 8px;
  color: white;
}


.resources-container {
  padding-top: 10px !important;
  white-space: nowrap;
  text-align: left;
  overflow-y: hidden;
  overflow-x: auto;
}

.node-image {
  width: 200px !important;
  height: 135px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 0.375rem !important;
  display: inline-block;
  text-decoration: none;
  animation: addAnimation 1s ease;
}

.node-image:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
  margin-right: 8px;
}

.imageViewer .container {
  width: 100% !important;
  max-width: 100% !important;
}

.img-info {
  display: table;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
}


.resources-container::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
}

.resources-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #000;
}

.resources-container::-webkit-scrollbar-thumb {
  background: #ccc;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.resources-container::-webkit-scrollbar-corner {
  background: #000;
}

.collapse-button {
  padding: 0px;
  background-color: transparent;
  border: 0px;
  position: absolute;
  right: 69px;
  height: 26px;
}

@keyframes addAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}